import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo"
import Header from "../components/header"
import Wrapper from "../components/wrapper"
import Footer from "../components/footer"
import Thumb from "../components/thumb"
import Hero from "../components/hero"

const ProjectWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

const ProjectItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "ThumbImg-0 ThumbImg-0 ThumbImg-1"
    "ThumbImg-0 ThumbImg-0 ThumbImg-2"
    "ThumbImg-3 ThumbImg-5 ThumbImg-5"
    "ThumbImg-4 ThumbImg-5 ThumbImg-5"
    "ThumbImg-6 ThumbImg-6 ThumbImg-7"
    "ThumbImg-6 ThumbImg-6 ThumbImg-8";
`

const Work = ({ data: { allMdx } }) => {
  const { edges: posts } = allMdx
  const description =
    "View Oscar's recent work design such as user interface, logo design, logo identity, visual design, wireframe or mockup for web and mobile platform."

  return (
    <Wrapper>
      <SEO title="Work" description={description} />
      <Header />
      <main id="MainContent">
        <Hero />
        <ProjectWrapper>
          <ProjectItems>
            {posts.map(({ node: post }, index) => (
              <Thumb key={post.id} data={post} index={index} />
            ))}
          </ProjectItems>
        </ProjectWrapper>
      </main>
      <Footer />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query WorkPageQuery {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          frontmatter {
            color
            date(formatString: "MMM DD YYYY")
            tags
            title
            thumbImg {
              ...imageThumbProp
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

Work.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default Work
